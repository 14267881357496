import emailjs from 'emailjs-com';
import React from 'react';

const ContactForm = () => {

    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('service_nnegais', 'template_qdeq9k3', e.target, 'user_v6NxKL9YrwUETK2R5TLPt')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
              console.log(error.text);
        });
        e.target.reset()

    }

    return(
        <div>
            <div className="message-container">
                <div className="send-header-container">
                    <h4 className="send-header">Send a personal message and book a FREE 30 minute consultation today!</h4>
                </div>
                <div className="form-container">
                    <form onSubmit={sendEmail}>
                        <div className="form">
                            <div className="input-text">
                                <input type="text" className="form-control" placeholder="Name" name="from_name" id="from_name"/>
                            </div>
                            <div className="input-text">
                                <input type="email" className="form-control" placeholder="Email Address" name="reply_to" id="reply_to" />
                            </div>
                            <div className="input-text">
                                <textarea className="form-control" id='' cols="30" rows="8" placeholder="Your mesage" name="message"></textarea>
                            </div>
                            <div className="input-text submit-button-container">
                                <input type="submit" className="submit-button" value="Send Message" onClick={() => alert('Your message has been sent!')}></input>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
};

export default ContactForm;