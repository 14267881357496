import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Title from './components/Title';
import Image from './components/Image';
import Bio from './components/Bio'
import Bio2 from './components/Bio2';
import ContactForm from './components/ContactForm';
import Socials from './components/Socials';

function App() {
  return (
    <div className="App">
      <Title/>
      <Image/>
      <Bio2/>
      <Bio/>
      <ContactForm/>
      <Socials/>
    </div>
  );
}

export default App;
