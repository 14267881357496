import React from 'react';
import goals from '../images/pexels-markus-winkler-4052198.jpg'

const Bio = () => {
    return(
            <div className="bio-container bio-container3">
                <div className="bio">
                Tiffany’s passion for helping others was born at the age of 18 
                when she started her own business selling and marketing health products. 
                The products truly made a difference in her loved one’s lives, and lead her 
                to meet people like Bob Proctor, Darren Hardy, and Gary Vaynerchuk. 
                She knew from a young age her calling was to guide others in their own journeys, 
                leading them to an abundant and balanced life. Let’s get you out of 
                the suffering and limitation mentality, and into alignment.
                </div>
                <img src={goals} alt="goals" className="goals-image"/>
            </div>
    )
}

export default Bio;