import React from 'react';
import journal from '../images/pexels-lisa-fotios-851213.jpg'

const Bio2 = () => {
    return(
            <div className="bio-container bio-container2">
                <img src={journal} alt="journal" className="journal-image"/>
                <div className="bio2">
                Today, she supports individuals in answering the questions: 
                “Who Am I, What do I want, and How do I get it?” 
                Tiffany is a certified Mentor who combines life and health coaching. 
                She is passionate about supporting people out of suffering and 
                limitation and into fulfillment. Her use of intuition and compassion 
                help people breakthrough challenges that at one time seemed impossible.
                </div>
            </div>
    )
}

export default Bio2;