import React from 'react';
import Facebook from "super-tiny-icons/images/svg/facebook.svg";
import Twitter from "super-tiny-icons/images/svg/twitter.svg";
import Instagram from "super-tiny-icons/images/svg/instagram.svg";
import Tiktok from "super-tiny-icons/images/svg/tiktok.svg";

const Socials = () => {
    return(
        <div>
            <h4 className="socials-header">Follow and stay connected</h4>
            <div className="container">
                <div className="socials-container">
                    <a href="https://www.facebook.com/groups/853207382183400" target="_blank" rel="noreferrer">
                        <img src={Facebook} alt="https://www.facebook.com/groups/853207382183400" />
                    </a>
                    <a href="https://twitter.com/mentoredbytiff" target="_blank" rel="noreferrer">
                        <img src={Twitter} alt="https://www.instagram.com/mentoredbytiffany" />
                    </a>
                    <a href="https://www.instagram.com/mentoredbytiffany" target="_blank" rel="noreferrer">
                        <img src={Instagram} alt="https://www.instagram.com/mentoredbytiffany" />
                    </a>
                    <a href="https://www.tiktok.com/@mentoredbytiffany?lang=en" target="_blank" rel="noreferrer">
                        <img src={Tiktok} alt="https://www.tiktok.com/@mentoredbytiffany?lang=en" />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Socials;