import React from 'react';
import image from '../images/tiffany.jpg'

const Image = () => {
    return(
        <div className="image-container">
            <div className="profile-text">
            <p className="special-font">Meet your Mentor!</p>
            Tiffany Ellsworth is a Master Mentor and podcast co-host 
            who is committed to guiding people into their highest potential. 
            She left her stressful dead-end job at the age of 24 to pursue 
            a life she could be passionate about; however it did not come easily. 
            After being inspired by her own unexpected challenges and experiences, 
            she realized her journey was indeed her destination.
            </div>
            <div className="avatar-container">
                <img src={image} alt="Tiffany" className="tiffany-image"/>
            </div>
        </div>
    )
}

export default Image;