import React from 'react';
import title from '../images/MentoredbyTiffany-header.png';

const Title = () => {
    return(
            <div className="title-container">
                <div className="title-text">
                    <img className="title-image" src={title} alt="Mentored by Tiffany"/>
                </div>
            </div>
    )
}

export default Title;